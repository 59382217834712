.nivo-lightbox-theme-default * {
  -webkit-box-sizing: initial !important;
  -moz-box-sizing: initial !important;
  box-sizing: initial !important;
}

.nivo-lightbox-theme-default.nivo-lightbox-overlay {
  background: #0009;
}

.nivo-lightbox-theme-default .nivo-lightbox-content.nivo-lightbox-loading {
  background: url("loading.42457435.gif") 50% no-repeat;
}

.nivo-lightbox-theme-default .nivo-lightbox-nav {
  text-indent: -9999px;
  opacity: .5;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 8%;
  height: 80%;
  top: 10%;
}

.nivo-lightbox-theme-default .nivo-lightbox-nav:hover {
  opacity: 1;
  background-color: #0000;
}

.nivo-lightbox-theme-default .nivo-lightbox-prev {
  background-image: url("prev.dead6184.png");
  border-radius: 0 3px 3px 0;
}

.nivo-lightbox-theme-default .nivo-lightbox-next {
  background-image: url("next.de64f30b.png");
  border-radius: 3px 0 0 3px;
}

.nivo-lightbox-theme-default .nivo-lightbox-close {
  text-indent: -9999px;
  opacity: .5;
  background: url("close.38a8fee6.png") 5px 5px no-repeat;
  width: 16px;
  height: 16px;
  padding: 5px;
  display: block;
}

.nivo-lightbox-theme-default .nivo-lightbox-close:hover {
  opacity: 1;
}

.nivo-lightbox-theme-default .nivo-lightbox-title-wrap {
  bottom: -7%;
}

.nivo-lightbox-theme-default .nivo-lightbox-title {
  color: #fff;
  background: #000;
  border-radius: 30px;
  padding: 7px 15px;
  font: 14px / 20px Helvetica Neue, Helvetica, Arial, sans-serif;
}

.nivo-lightbox-theme-default .nivo-lightbox-image img {
  background: #fff;
  box-shadow: 0 1px 1px #0006;
}

.nivo-lightbox-theme-default .nivo-lightbox-ajax, .nivo-lightbox-theme-default .nivo-lightbox-inline {
  background: #fff;
  padding: 40px;
  box-shadow: 0 1px 1px #0006;
}

@media (-webkit-device-pixel-ratio >= 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-moz-device-pixel-ratio >= 1.3), (min-device-pixel-ratio: 1.3), (resolution >= 1.3x) {
  .nivo-lightbox-theme-default .nivo-lightbox-content.nivo-lightbox-loading {
    background-image: url("loading@2x.a63c93f9.gif");
    background-size: 32px 32px;
  }

  .nivo-lightbox-theme-default .nivo-lightbox-prev {
    background-image: url("prev@2x.441d3327.png");
    background-size: 48px 48px;
  }

  .nivo-lightbox-theme-default .nivo-lightbox-next {
    background-image: url("next@2x.6ee06a76.png");
    background-size: 48px 48px;
  }

  .nivo-lightbox-theme-default .nivo-lightbox-close {
    background-image: url("close@2x.1713fe16.png");
    background-size: 16px 16px;
  }
}
/*# sourceMappingURL=index.cdeb64ab.css.map */
